<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <s-toolbar dark search-input label="Configuración de Permisos">
    </s-toolbar>

    <v-row>
        <v-col cols="6">
            <s-crud height="auto" :filter="filter" title="Áreas" :config="configArea" @rowSelected="rowSelected($event)">

            </s-crud>
        </v-col>
        <!-- <v-col cols="6">
            <v-card elevation="0" outlined>
                <s-toolbar label="Permisos" search-input @removed="deleteEvent()" remove></s-toolbar>
                <v-col>
                    <v-row justify="center">

                        <v-col cols=12>
                            <s-select-definition style="border-color: seashell;border-radius: 7px;" :def="1142" v-model="TypeBusiness">

                            </s-select-definition>
                        </v-col>
                        <v-col cols=12>
                            <c-center-coste clearable noDefault full label="Part. Costo" :business="TypeBusiness" :areID="0" return-object v-model="objCoste"></c-center-coste>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col class="pt-1" lg=5>
                            <v-btn @click='item={}' block small>Nuevo</v-btn>
                        </v-col>
                        <v-col class="pt-1" lg=5>
                            <v-btn @click=save(item) block small color="primary">Guardar</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-data-table show-select :items=itemsPermit dense item-key="CcaID" :headers=this.config.headers v-model=selectedPermit :single-select="true">
                </v-data-table>

            </v-card>

        </v-col> -->
        <v-col cols="6">
            <v-row>

                <v-col cols="12">
                    <v-card elevation="0" outlined>
                        <s-toolbar label="Centro Costos" search-input @removed="deleteEvent()" remove></s-toolbar>
                        <v-col>
                            <v-row justify="center">

                                <v-col cols=12>
                                    <s-select-definition style="border-color: seashell;border-radius: 7px;" :def="1142" v-model="TypeBusiness">

                                    </s-select-definition>
                                </v-col>
                                <v-col cols=12 style="margin-top:-20px">
                                    <c-center-coste clearable noDefault full label="Part. Costo" :business="TypeBusiness" :areID="0" return-object v-model="objCoste"></c-center-coste>
                                </v-col>
                                 <v-col cols=12 style="margin-top:-20px">
                                    <c-select-area full label="Area" v-model="AreParentID" :typeArea="3"></c-select-area>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="pt-1" lg=5>
                                    <v-btn @click='item={}' block small>Nuevo</v-btn>
                                </v-col>
                                <v-col class="pt-1" lg=5>
                                    <v-btn @click=save(item) block small color="primary">Guardar</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-data-table show-select :items=itemsPermit dense item-key="CcaID" :headers=this.config.headers v-model=selectedPermit :single-select="true">
                        </v-data-table>

                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="0" outlined>
                        <s-toolbar label="Gastos Fijos" search-input @removed="deleteGasto()" remove></s-toolbar>
                        <v-col>
                            <v-row justify="center">

                                <v-col cols=12>
                                    <c-type-spent clearable noDefault full label="Part. Gasto" return-object :areID="0" v-model="objGF"></c-type-spent>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="pt-1" lg=5>
                                    <v-btn @click='itemGasto={}' block small>Nuevo</v-btn>
                                </v-col>
                                <v-col class="pt-1" lg=5>
                                    <v-btn @click=saveGasto(itemGasto) block small color="primary">Guardar</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-data-table show-select :items=itemsGasto dense item-key="GsfID" :headers=this.configGasto.headers v-model=selectedGasto :single-select="true">
                        </v-data-table>

                    </v-card>
                </v-col>
            </v-row>

        </v-col>
    </v-row>

</div>
</template>

<script>
//Services
import _sPermit from "@/services/Logistics/PermitService";
import _sArea from "@/services/General/AreaService.js";

import cCenterCoste from '@/components/Logistics/cCenterCoste.vue';
import cTypeSpent from '@/components/Logistics/cTypeSpent.vue';

export default {
    components: {
        cCenterCoste,
        cTypeSpent
    },
    props: {
        model: false
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
        itemsPermit: [],
        itemsGasto:[],
        item: null,
        itemGasto:{},
        selectedPermit: [],
        selectedGasto: [],
        config: {
            model: {
                CcaID: 'ID',
                CceCenterCoste: 'string'
            },
            headers: [{
                    text: 'ID',
                    value: 'CcaID'
                },  {
                    text: 'Area Padre',
                    value: 'AreParentName'
                },{
                    text: 'Empresa',
                    value: 'TypeBusinessName'
                },

                {
                    text: 'Centro de Costo',
                    value: 'CceCenterCoste'
                },
            ],

            service: _sPermit
        },
        configGasto: {
            model: {
                CcaID: 'ID',
                CceCenterCoste: 'string'
            },
            headers: [{
                    text: 'ID',
                    value: 'GsfID'
                }, 
                {
                    text: 'Gasto',
                    value: 'GFName'
                },
            ],

            service: _sPermit
        },
        filter: {
            TypeArea: 0,
            AreID: 0
        },
        configArea: {
            model: {
                AreID: "ID",
                PstManagerID: "int",
            },
            service: _sArea,
            headers: [{
                    text: "ID",
                    value: "AreID",
                    sortable: false,
                    width: 20
                },
                {
                    text: "Área",
                    value: "AreName",
                    sortable: false
                },
                {
                    text: "Abreviación",
                    value: "AreAbbreviation",
                    sortable: false
                },
            ],
        },
        TypeBusiness: null,
        AreID: 0,
        AreParentID: null,
        objCoste: null,
        objGF:null
    }),

    methods: {
        rowSelected(items) {

            if (items.length > 0) {
                this.item = items[0];
                this.AreID = items[0].AreID
                _sPermit.byArea({
                    AreID: this.item.AreID
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsPermit = r.data.listCosto
                    this.itemsGasto = r.data.listGasto;
                })
            } else this.item = null;

        },

        //COSTO
        deleteEvent() {
            if (this.selectedPermit.length > 0) {
                this.selectedPermit[0].SecStatus = 0;
                this.selectedPermit[0].UsrUpdateID = this.$fun.getUserID();
                _sPermit.save(this.selectedPermit[0], this.$fun.getUserID()).then((r) => {
                    _sPermit.byArea({
                        AreID: this.item.AreID
                    }, this.$fun.getUserID()).then((r) => {
                        this.itemsPermit = r.data.listCosto
                    })
                });
            }
        },

        save(item) {

            if(this.objCoste == null) return;

            this.item.AreID = this.AreID
            this.item.AreParentID = this.AreParentID
            this.item.TypeBusiness = this.TypeBusiness
            this.item.CceCenterCoste = this.objCoste == null ? "" : this.objCoste.CceCenterCoste
            this.item.UsrCreateID = this.$fun.getUserID();
            this.item.SecStatus = 1

            _sPermit.save(this.item, this.$fun.getUserID()).then((r) => {
                _sPermit.byArea({
                    AreID: this.item.AreID
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsPermit = r.data.listCosto
                })
            });
        },

        //GASTO
        saveGasto(item) {
            if(this.objGF == null == null) return;

            this.itemGasto.AreID = this.AreID
            this.itemGasto.GFName = this.objGF == null ? "" : this.objGF.SptID
            this.itemGasto.UsrCreateID = this.$fun.getUserID();
            this.itemGasto.SecStatus = 1

            console.log("ave",this.itemGasto)

            _sPermit.saveGasto(this.itemGasto, this.$fun.getUserID()).then((r) => {
                _sPermit.byArea({
                    AreID: this.itemGasto.AreID
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsGasto = r.data.listGasto;
                })
            });
        },

        deleteGasto() {
            if (this.selectedGasto.length > 0) {
                this.selectedGasto[0].SecStatus = 0;
                this.selectedGasto[0].UsrUpdateID = this.$fun.getUserID();
                _sPermit.saveGasto(this.selectedGasto[0], this.$fun.getUserID()).then((r) => {
                    _sPermit.byArea({
                        AreID: this.item.AreID
                    }, this.$fun.getUserID()).then((r) => {
                        this.itemsGasto = r.data.listGasto;
                    })
                });
            }
        }
    },

};
</script>
